<template>
  <dash-page-new
      :title="$t('Courses') + (selectedCategoryName ? (': ' + selectedCategoryName) : '')"
      :root="$t('Dashboard')"
      :subtitle="$t('AllCourses')"
      :root-location="businessDashLink('')"
      icon="mdi-school"
      :filters="filtersSelect"
      :filters-input="filters"
      @filter="filters = $event"
      no-side
  >

    <template #header_action.desktop>

      <ws-button
          v-if="itemsFiltered.length > 0 &&  MODULE_PERMISSIONS(['MODULE_WESTUDY__EDITOR'], true)"
          label="CreateNewCourse"
          left-icon="mdi-plus"
          @click="openAddItem"
          :color="wsATTENTION"
      />

    </template>
    <template #header_action.mobile>
      <v-btn v-if="itemsFiltered.length > 0"  icon
             @click="openAddItem"  :color="wsATTENTION">
        <v-icon class="mr-2" large  >mdi-plus-circle</v-icon>
      </v-btn>
    </template>

    <template #default>

      <v-row  no-gutters  style="flex-wrap: nowrap; height: 100% !important" class="fill-height mt-5"  >

        <!-- Categories-->
        <v-col :style="`min-width: ${categoriesWidth}px; max-width: ${categoriesWidth}px; height: 100% !important;`"
               style="transition: all 0.3s ease"
               class="pr-4 pb-6 flex-grow-0 flex-shrink-0 sideTransition fill-height"
        >

          <v-sheet v-if="!hideCategories"
              class="fill-height wsRoundedLight overflow-y-auto"
              :style="`border : 1px solid ${wsBACKGROUND}`"
          >
            <v-sheet :color="wsLIGHTCARD" height="46"
                     class="d-flex  wsRoundedLight align-center"
                     :class="!hideCategories ? 'px-2 justify-space-between' : 'justify-center'"
            >
              <ws-tooltip v-if="!hideCategories && MODULE_PERMISSIONS(['MODULE_WESTUDY__EDITOR'], true)" :text="$t('AddCategory')">
                <v-btn :color="wsACCENT"  @click="addCategoryItem('category')" icon>
                  <v-icon >mdi-folder-plus-outline</v-icon>
                </v-btn>
              </ws-tooltip>

              <h4 v-if="!hideCategories" :class="MODULE_PERMISSIONS(['MODULE_WESTUDY__EDITOR'], true) ? 'pl-4' : '' " > {{ $t('Categories') }}</h4>

              <v-btn @click="hideCategoriesAction" :color="wsACCENT" icon><v-icon>mdi-chevron-{{ !hideCategories ?  'left' : 'right' }}</v-icon></v-btn>

            </v-sheet>

            <v-divider   :style="`border-color : ${wsBACKGROUND}`" ></v-divider>

            <template  v-for="(item,i) in rootCategories">
              <v-sheet v-if="!hideCategories" height="48"  :key="'parent_category_' + i">
                <v-hover v-slot="{hover}" >

                  <div class="fill-height">
                    <v-sheet
                        @click="selectCategory(item)"
                        :color="hover || selectedCategory === item.value ? wsLIGHTCARD : ''"
                        :style="`border-bottom : 1px solid ${wsBACKGROUND}`"
                        class="d-flex align-center justify-space-between px-4 fill-height"
                        :class="[{'pointer' : childCategories(item.value).length === 0}]"
                    >
                      <h5 :style="`color : ${wsACCENT}`" class="d-flex align-center">
                        {{  item.text  }}
                        <wsAddItem
                            v-if="item.value !== 'all' && item.value !== 'unsorted' && MODULE_PERMISSIONS(['MODULE_WESTUDY__EDITOR'], true)"
                            inline
                            @input="editCategoryAction($event,item)"
                            :hover="hover"
                            :items="categodyAddItemsSelect"
                        />

                      </h5>
                      <h5>{{  courseCategoryCount(item.value)  }}</h5>
                    </v-sheet>



                  </div>

                </v-hover>
              </v-sheet>
              <template v-for="(child,i) in childCategories(item.value)">
                <v-sheet  v-if="!hideCategories"
                          height="48"  :key="'child_category_' + i">
                  <v-hover v-slot="{hover}" >

                    <div class="fill-height">
                      <v-sheet
                          @click="selectedCategory = child.value"
                          :color="hover || selectedCategory === item.value ? wsLIGHTCARD : ''"
                          :style="`border-bottom : 1px solid ${wsBACKGROUND}`"
                          class="d-flex align-center justify-space-between px-4 fill-height pointer"
                      >
                        <h5 class="pl-6 d-flex align-center" >
                          {{  child.text  }}
                          <wsAddItem
                              v-if="MODULE_PERMISSIONS(['MODULE_WESTUDY__EDITOR'], true)"
                              @input="editCategoryAction($event,child)"
                              :hover="hover"
                              inline
                              :items="categodyAddSubItemsSelect"
                          />
                        </h5>
                        <h5 >{{  courseCategoryCount(child.value)   }}
                        </h5>
                      </v-sheet>
                    </div>

                  </v-hover>


                </v-sheet>
              </template>

            </template>



          </v-sheet>
          <ws-tooltip v-else  :text="$t('Categories')">
            <v-btn @click="hideCategoriesAction" :color="wsACCENT" icon><v-icon>mdi-file-tree</v-icon></v-btn>
          </ws-tooltip>


        </v-col>
        <!-- Courses Table-->
        <v-col style="min-width: 100px; max-width: 100%;position:relative" class="flex-grow-1 flex-shrink-0 pb-6">

          <div style="height: 100%;" :style="`border : 1px solid ${wsBACKGROUND}`" class="d-flex flex-column wsRoundedLight">
            <div   class="flex-grow-1 overflow-y-auto" style="height: 100%;">
              <ws-data-table
                  v-if="itemsFiltered.length > 0"
                  :items="itemsFiltered"
                  :headers="headers"
                  :search="filters.search"
                  footer-portal="courses_footer"
              >

                <template v-slot:item="{item}">
                  <v-hover v-slot="{hover}">
                    <tr @click="$router.push(businessDashLink(gotoCourseRoute(item.uuid)))"
                        @contextmenu="openContextMenu($event, item)"
                        :style="`background : ${ hover ? wsLIGHTCARD : ''} !important`"
                        style="cursor: pointer !important;"
                    >

                      <td :style="`border-color: ${wsBACKGROUND}`">
                        <router-link
                            :to="businessDashLink(gotoCourseRoute(item.uuid))"
                            :style="`color : ${wsACCENT} !important`"
                            class="noUnderline" >

                          <div class="d-flex align-center shorten-text-two linkHover" style="min-width: 250px">
                            <h5 class="shorten-text-two">{{ item.name }}</h5>
                          </div>

                        </router-link>
                      </td>
                      <td>
                        <h5 class="text-center pr-4"> {{ item.students_count }}</h5>
                      </td>
                      <td  width="150px">
                        <wsChip v-if="item.payed"  :color="wsACCENT" icon="mdi-wallet" >
                          <h5 :style="`color: ${wsACCENT}`">{{ !item.has_payment_plans ? (item.price + ' ' + BUSINESS_CURRENCY) : $t('PaymentPlan') }}</h5>
                        </wsChip>
                        <h5 v-else>{{ $t('Free') }}</h5>
                      </td>
                      <td align="center">
                        <v-icon :color="item.active ? wsSUCCESS : wsBACKGROUND">mdi-check-circle-outline</v-icon>
                      </td>
                      <td>
                        <ft-select
                            @expand="selectedCourse = item"
                            @input="editCourseAction($event)"
                            :items="editCourseActionsSelect"
                        >
                          <v-btn icon :color="wsACCENT">
                            <v-icon>mdi-dots-horizontal</v-icon>
                          </v-btn>

                        </ft-select>
                      </td>
                    </tr>
                  </v-hover>
                </template>


              </ws-data-table>
              <div v-else
                   class="wsRoundedLight
         d-flex
         fill-height
         justify-center
         align-center
         pb-16 pt-8 py-6 " >

                <v-sheet  width="600">

                  <div class="d-flex justify-center">
                    <v-icon size="80" :color="wsDARKLIGHT" >mdi-calendar</v-icon>
                  </div>
                  <h3 style="font-size: 22px; font-weight: 700" class="text-center  my-6">
                    <span v-if="items.length === 0">{{ $t('DashNoCoursesYet') }}</span>
                    <span v-else-if="itemsFiltered.length === 0">{{ $t('DashNoCategoryCoursesYet') }}</span>
                  </h3>
                  <div class="d-flex justify-center">
                    <v-btn height="40" @click="openAddItem" dark :color="wsATTENTION" class="noCaps">
                      <v-icon>mdi-plus</v-icon>
                      {{ $t('CreateNewCourse') }}

                    </v-btn>
                  </div>
                </v-sheet>

              </div>
            </div>
            <div>
              <v-divider  v-if="itemsFiltered.length > 0" :style="`border-color : ${wsBACKGROUND}`" />
              <portal-target name="courses_footer"></portal-target>
            </div>
          </div>


        </v-col>

      </v-row>

    </template>

    <template #dialog>
      <!-- Add / Edit Item Dialog Window -->
      <wsDialog v-model="displayDialog"
                :loading="LOADING"
                @save="nextStep"
                :title="$t('CreateNewCourse')"
                :saveText="saveText"
                :cancel-action="newCourseStep === 1"
                :cancel-text="cancelText"
                @cancel="newCourseStep = 0 ; newCourseNavigation = 'description'"
                :showDelete="!newEntity"
                @delete="deleteItem" >

        <wsNavigationHeader
            locked
            v-model="newCourseNavigation"
            :items="newCourseNavigationItems"
            class="mb-4  "
        />



        <div v-if="newCourseStep === 0 ">
          <div v-if="(selectedCategory === 'all' || selectedCategory === 'unsorted') && categories.length > 0 " class="pb-2">
            <v-row class="pb-2 px-0 mx-0">
              <v-col cols="6" class="px-0 pr-1">
                <ws-select
                    v-model="entityData.category_id"
                    :items="rootCategoriesSelect"
                    :label="$t('Category')"
                    :placeholder="$t('ChooseCategory')"
                    :error="categoryError"
                    @input="categoryError = false"
                />
              </v-col>
              <v-col cols="6" class="px-0 pl-1">
                <v-expand-x-transition>
                  <ws-select
                      v-if="childCategories(entityData.category_id).length > 0"
                      v-model="entityData.sub_category_id"
                      :items="childCategories(entityData.category_id)"
                      :label="$t('SubCategory')"
                      :placeholder="$t('ChooseSubCategory')"
                  />
                </v-expand-x-transition>
              </v-col>
            </v-row>
          </div>
          <ws-text-field
              :label="$t('Name')"
              :placeholder="$t('CourseName')"
              v-model="entityData.name"
              :error="nameError"
              @input="nameError = false"
          />
          <ws-text-field
              class="mt-6"
              v-model="entityData.short_description"
              :label="$t('ShortDescription')"
              :placeholder="$t('EnterShortDescription')"
              counter="200"
              area
          />

        </div>
        <div v-if="newCourseStep === 1 ">

          <ws-toggle
              v-model="entityData.online"
              :label="$t('EnrollmentSchedule') + ': '"
              :description="$t('EnrollmentScheduleDescription')"
              :text-true="$t('HasEnrollmentSchedule')"
              :text-false="$t('NoEnrollmentSchedule')"
              class="mb-2"
          />

          <v-expand-transition>
            <h5 v-if="entityData.online" style="font-size: 13px; font-weight: 400 " class="mb-4">
              {{ $t('EnrollmentScheduleExplanation') }}
            </h5>
          </v-expand-transition>

          <ws-toggle
              v-model="entityData.payed"
              :label="$t('Payment') + ': '"
              :description="$t('IsCoursePayed')"
              :text-true="$t('Payed')"
              :text-false="$t('Free')"
              class="mb-2"
          />

          <v-expand-transition>
            <div v-if="entityData.payed">

              <ws-toggle
                  v-model="entityData.has_payment_plans"
                  :label="$t('PaymentPlans') + ': '"
                  :description="$t('PaymentPlansQuestion')"
                  :text-true="$t('PaymentPlansOn')"
                  :text-false="$t('NoPaymentPlans')"
                  class="mb-2"
              />

              <v-expand-transition mode="out-in">
              <div v-if="!entityData.has_payment_plans">
                <ws-text-field
                    v-model="entityData.price"
                    :label="$t('CoursePrice') + ' (' + BUSINESS_CURRENCY + ')'"
                    :placeholder="$t('Price')"
                    :error="priceError"
                    @input="priceError = false"
                    number
                />
              </div>
              <h5 v-else class="font-weight-light mt-3">{{ $t('PaymentPlansExplanation') }}  </h5>
              </v-expand-transition>

            </div>
          </v-expand-transition>




        </div>

        <div v-if="newCourseStep === 2 ">

          <div v-if="LOADING" class="fill-height d-flex align-center justify-center">
            <v-progress-circular  indeterminate />
          </div>


          <div>

            <h3 class="font-weight-medium text-center">{{ $t('NewCourseCreated') }}</h3>

          </div>

        </div>


      </wsDialog>
      <ft-select
          @input="editCourseAction($event)"
          :items="editCourseActionsSelect"
          :expanded="displayContextMenu"
          :x="x" :y="y"
          absolute
      />
      <ws-dialog
          v-model="displayCategoryDialog"
          :title="editCategory ? $t('EditCategory') : $t('AddCategory')"
          @save="addEditCategory"
      >
        <ws-text-field
            v-model="entityData.name.ua"
            :label="$t('Name')"
            :placeholder="$t('EnterName')"
        />

      </ws-dialog>
      <ws-dialog
          v-model="displayCopyCourse"
          :title="$t('CopyCourse')"
          @save="copyCourse"
      >

        <ws-text-field v-model="entityData.name"  />

      </ws-dialog>

    </template>

  </dash-page-new>
</template>

<script>

import {mapActions, mapState} from "vuex";

export default {
  name: "PageCourses",
  components : {
  },
  data() {
    return {
      filters : {},

      displayCopyCourse : false,

      //context menu
      hideCategories : false,
      categoriesWidth : 280,
      selectedCourse : {},
      displayContextMenu : false,
      x : null,
      y : null,

      newCourseNavigation : 'description',
      categories : [],
      selectedCategory : 'all',
      displayCategoryDialog : false,
      editCategory : false,
      priceError : false,
      nameError : false,
      categoryError : false,


      loading : false,
      newCourseStep : 0,
      items : [],
      dialogType : 'new',
      newEntity  : true,
      displayDialog : false,
      entityData : {
        name : '',
        short_description : '',
        price : 0,
      },
      newEntityData : {},
    }
  },
  computed : {
    ...mapState('courses', [ 'selectedLang' ]),
    filtersSelect() {
      return [
        { text : this.$t('Active')  , value : 'status' , type : 'select' , items : this.CHOICE_ACTIVE_SELECT },
        { text : this.$t('Payment')  , value : 'payed' , type : 'select' , items : [
            { text : this.$t('All')    , value: null },
            { text : this.$t('Payed') , value: true },
            { text : this.$t('Free') , value: false }
          ]},
      ]
    },
    editCourseActionsSelect() {
      let items = [
        { text : this.$t('CourseEditor') , route : `courses/${this.selectedCourse.uuid}/editor`, icon : 'mdi-pencil' },
        { text : this.$t('CopyCourse') , value : 'copy', icon : 'mdi-content-copy' },
      ]

      if (this.categories.length > 0 ) {
        items.splice(0,0,
            { text : this.$t('ChangeCategory') , value : 'category' , icon : 'mdi-folder-multiple', children : this.categories_select },)
      }

      return items
    },
    entityDataDefault() {
      return {
        name : '',
        short_description : '',
        lang : 'ua',
        alias : '',
        payed : false,
        online : false,
        has_payment_plans : false
      }
    },

    pageNavigationFiltered() {

      let items = this.COURSE_NAVIGATION_ADMIN(this.$store.state.courses.course)
      items = items.filter(el => !el.permission || this.MODULE_PERMISSIONS([el.permission], true))

      return items

    },

    categories_select() {
      let result = []
      let items = JSON.parse(JSON.stringify(this.rootCategories))
      items.splice(0,1)
      items.splice(items.length -1 ,1)
      items.forEach(item => {
        item.icon = 'mdi-folder-outline'
        item.action = () => this.changeCourseCategory(item.value)
        let subCategories = this.childCategories(item.value)
        if ( subCategories.length > 0) {
          item.icon = 'mdi-folder-multiple-outline'
          result.push(item)
          subCategories.forEach(child => {
            child.icon = 'mdi-chevron-right'
            child.action = () => this.changeCourseCategory(child.value)
            result.push(child)
          })

        } else {
          result.push(item)
        }
      })
      result.unshift({
        text : this.$t('NoCategory'),
        value : null,
        icon : 'mdi-close',
        action : () => this.changeCourseCategory(null)
      })

      return result;
    },
    newCourseNavigationItems() {
      return [
        { text : this.$t('Description') , value : 'description' },
        { text : this.$t('Settings') , value : 'settings' },
      ]
    },
    selectedCategoryName() {
      if (this.categories.length === 0 ) {
        return ''
      }

      let category = this.categories.find( el=> el.value === this.selectedCategory )

      return category ? category.text : ''

    },
    categodyAddItemsSelect() {
      return [
        { text : this.$t('EditName') , value : 'edit' , icon : 'mdi-pencil' },
        { text : this.$t('AddSubCategory') , value : 'sub_category' , icon : 'mdi-folder-multiple-plus-outline' },
        { text : this.$t('Delete') , value : 'delete'        , icon : 'mdi-delete-outline' },
      ]
    },
    categodyAddSubItemsSelect() {
      return [
        { text : this.$t('EditName') , value : 'edit' , icon : 'mdi-pencil' },
        { text : this.$t('Delete') , value : 'delete'        , icon : 'mdi-delete-outline' },
      ]
    },
    headers() {
      return [
        { text  : this.$t('Name') , value : 'name' },
        { text  : this.$t('Students') , value : 'students_count' , width : 10 },
        { text  : this.$t('Payment') , value : 'payed' , width : 10 },
        { text  : '', value : 'active' , align : 'center' ,width : 10 , padding : 1 , sortable: false },
        { value : 'copy' , width : 10 , sortable : false }
      ]
    },
    saveText() {
      if ( !this.newEntity ) { return this.$t('Save')  }
      if ( this.newCourseStep === 0 ) { return this.$t('Next') }
      if ( this.newCourseStep === 2 && !this.LOADING ) { return this.$t('GoToCourseEdit') }


      return this.$t('Save')
    },
    cancelText() {
      if ( this.newCourseStep === 2 ) { return this.$t('Close') }
      return this.newCourseStep === 1 ? this.$t('GoBack') : this.$t('Cancel')
    },
    rootCategories() {
      if ( this.categories.length === 0 ) {
        return [{text: this.$t('All'), value: 'all'}]
      }
      let items = this.categories.filter( el => el.parent === null )
      items.unshift({text: this.$t('All'), value: 'all'} )
      items.push({text: this.$t('UnsortedCourses'), value: 'unsorted'} )
      return items
    },
    rootCategoriesSelect() {
      if (this.categories.length === 0) {
        return
      }
      let items = JSON.parse(JSON.stringify(this.rootCategories))
      items.splice(items.length - 1 , 1)
      return items
    },
    itemsFiltered() {
      let items = this.items
      if (this.selectedCategory && this.selectedCategory !== 'all') {
       let category = this.selectedCategory !== 'unsorted' ? this.selectedCategory : null
        items = items.filter( el => el.category_id === category )
      }
      if ( [true,false].includes(this.filters.status) ) {
        if ( this.filters.status ) {
          items = items.filter( el => el.active  )
        } else {
          items = items.filter( el => !el.active  )
        }
      }

      if ( [true,false].includes(this.filters.payed) ) {
        if ( this.filters.payed ) {
          items = items.filter( el => el.payed  )
        } else {
          items = items.filter( el => !el.payed  )
        }
      }
      return items
    }
  },
  methods : {
    ...mapActions('courses', [
      'ADD_COURSE_CATEGORY',
      'CHANGE_COURSE_CATEGORY',
      'DELETE_COURSE_CATEGORY',
      'ADD_COURSE',
      'GET_COURSES',
      'GET_COURSE',
      'COPY_COURSE',
      'DELETE_COURSE'
    ]),


    async copyCourse() {
      let result = await this.COPY_COURSE(this.entityData)
      if ( !result ) {
        return
      }
      result.name = this.entityData.name
      result.students_count = 0
      this.items.push(result)
      this.notify('CourseCopied' , 'success')
      this.displayCopyCourse = false
    },
    openCopyCourse(item) {
      this.entityData = {
        uuid : item.uuid,
        name : this.$t('CopySingle') + ' : ' + item.name ,
        lang : this.selectedLang
      }
      this.displayCopyCourse = true

    },

    gotoCourseRoute(uuid) {



      if (this.pageNavigationFiltered.length === 0 ) {
        return 'courses/'
      }

      return this.MODULE_PERMISSIONS(['MODULE_WESTUDY__EDITOR'], true)
          ? 'courses/' + uuid + '/' + this.$store.state.courses.navigationAdmin
          : 'courses/' + uuid + '/' + this.pageNavigationFiltered[0].value
    },

    hideCategoriesAction() {
      if (this.hideCategories) {
        this.hideCategories = false
        this.categoriesWidth = 280
        return
      }
      if (!this.hideCategories) {
        this.hideCategories = true
        this.categoriesWidth = 50
        return
      }
    },

    async changeCourseCategory(categoryId) {
      let data = {
        uuid : this.selectedCourse.uuid,
        category_id : categoryId
      }
      let result = await this.CHANGE_COURSE_CATEGORY(data)
      if ( !result ) {
        return
      }
      let index = this.items.findIndex( el => el.uuid === this.selectedCourse.uuid )
      if ( index === -1 ) {
        return
      }
      this.items[index].category_id = categoryId
      this.notify(this.$t('CourseCategoryChanged'))
    },
    async editCourseAction(action) {
      if ( action === 'copy'  ) {
        return this.openCopyCourse(this.selectedCourse)
      }

    },
    openContextMenu($event, course) {

      this.selectedCourse = course
      $event.preventDefault()
      this.displayContextMenu = false
      this.x = $event.clientX
      this.y = $event.clientY
      this.$nextTick(() => {
        this.displayContextMenu = true
      })
    },

    selectCategory(item) {
      if ( this.childCategories(item.value).length === 0 ) {
        this.selectedCategory = item.value
      }
    },
    async addEditCategory() {

      let result = await this.ADD_COURSE_CATEGORY(this.entityData)
      if ( !result ) {
        return
      }
      if ( !this.editCategory ) {
        this.categories.push(result)
      }
      else {

        let index = this.categories.findIndex( el=> el.value === result.value )
        if ( index === -1 ) {
          return
        }
        this.categories[index].text = result.text
        this.categories = JSON.parse(JSON.stringify(this.categories))
      }

      this.displayCategoryDialog = false
    },
    async deleteCategory(item) {
      let result = await this.DELETE_COURSE_CATEGORY(item.value)
      if ( !result ) {
        return
      }

      this.categories = this.categories.filter( el=> el.value !== item.value )
      let categoriesArray = this.categories.filter( el=> el.parent === item.value )
      this.categories = this.categories.filter( el=> el.parent !== item.value )
      categoriesArray = categoriesArray.map(el => el.value)
      categoriesArray.push(item.value)
      this.items.forEach(course => {
        if ( categoriesArray.includes(course.category_id) ) {
          course.category_id = null
        }
      })
      if ( this.selectedCategory === item.value ) {
        this.selectedCategory = 'all'
      }
    },

    editCategoryAction($event, item) {

      if ( $event === 'edit' ) {
        this.editCategory = true
        this.entityData = {
          name : { ua : item.text },
          uuid : item.value
        }
        this.displayCategoryDialog = true
      }
      if ( $event === 'delete' ) {
        this.deleteCategory(item)
      }
      if ( $event === 'sub_category' ) {
        this.editCategory = false
        this.entityData = {
          name : { ua : '' },
          parent : item.value
        }
        this.displayCategoryDialog = true
      }
    },
    addCategoryItem($event, item ) {
      let type = ''
      if ( item && item.value ) {
        type = $event ===  'sub_category' ? item.value : null
      }

      this.editCategory = false
      this.entityData = {
        name : { ua : '' },
        parent : type
      }
      this.displayCategoryDialog = true
    },

    validateName() {
      let data = 0;
      Object.keys(this.entityData.name).forEach(lang=> {
        if ( this.entityData.name[lang] ) {
          data++;
        }
      })
      return data > 0
    },
    nextStep() {
      if ( !this.validateName() ) {
        this.nameError = true
        return this.notify(this.$t('CourseNameIsEmpty') )
      }
      // if ( this.IS_BUSINESS_MANAGER && this.MODULE_PERMISSIONS(['MODULE_WESTUDY__IS_LIMITED'], true) && !this.entityData.category_id ) {
      //   this.categoryError = true
      //   return this.notify(this.$t('CourseCategoryNotSet'))
      // }
      if ( this.newCourseStep === 0 ) {
        this.newCourseNavigation = 'settings'
      }
      if ( this.newCourseStep === 1 ) {
        if (this.entityData.payed && !this.entityData.has_payment_plans && !this.entityData.price ) {
          this.priceError = true
          return this.notify(this.$t('PriceNotSet'))
        }
      }
      this.newCourseStep++
      if ( this.newCourseStep === 2 ) {
        this.addEditItem()
      }
      if(this.newCourseStep === 3 ) {
        this.$router.push(this.businessDashLink(this.gotoCourseRoute(this.newEntityData.uuid)))
      }
    },

    // Entity functions
    addEditItem() {

      this.newEntity
         ? this.newItem(this.ADD_COURSE(this.entityData))
         : this.changeItem(this.ADD_COURSE(this.entityData))

    },
    deleteItem() {

      this.removeItem(this.DELETE_COURSE(this.entityData.uuid) )
      this.displayDialog = false

    },

    // Reactivity functions
    async changeItem(request) {

      let item = await request

      if ( item ) {
        var index = this.items
            .findIndex(el => el.uuid === item.uuid )
        this.items[index] = item
        this.$set(this.items,index,item)
        this.notify( this.$t('ChangesSaved')  )
      }

      this.displayDialog = false

    },
    async newItem(request) {

      let item = await request

      if ( item ) {
        this.items.push( item )
        this.notify( this.$t('NewCourseCreated' ))
      }
      this.newEntityData = item

    },
    async removeItem(deleteAction) {

      if ( !await deleteAction ) {
       return this.notify(this.$t('EntityCannotBeDeleted'))
      }

      var index = this.items
          .findIndex(el => el.uuid === this.entityData.uuid )
      this.items.splice(index,1)
      this.notify(this.$t('CourseDeleted'))

    },

    // Dialog functions
    openAddItem(){
      this.newCourseNavigation = 'description'
      this.newCourseStep = 0;
      this.flushEntity()
      let category  = this.categories.find(el => el.value === this.selectedCategory )
      if ( category ) {
        this.entityData.category_id = !category.parent ? category.value : category.parent
        this.entityData.sub_category_id = category.value
      }

      this.displayDialog = true
      this.newEntity = true
    },
    async openEditItem(item) {

      this.newEntity = false
      this.entityData = await this.GET_COURSE(item.uuid) || this.entityDataDefault
      this.displayDialog = true;
    },

    // Technical
    flushEntity() {
      this.entityData = JSON.parse(JSON.stringify(this.entityDataDefault))
    },
    childCategories(parentId) {
      return this.categories.filter(el => el.parent === parentId )
    },
    courseCategoryCount(uuid) {
      let items = this.items
      if (uuid && uuid !== 'all') {
        uuid = uuid !== 'unsorted' ? uuid : null
        items = items.filter( el => el.category_id === uuid )
      }
      return items.length
    },

    // Page init
    async initPage(){
      this.flushEntity()

      let result = await this.GET_COURSES();
      if ( !result ) { this.notify(this.$t('ConnectionError'))}
      this.items = result.courses;
      this.categories = result.categories || []


    }
  },

  mounted() {
    this.$store.state.courses.navigationAdmin = 'editor'
    this.initPage()
    // ONBOARD_COURSES(this)
  }
}
</script>

<style scoped>

</style>